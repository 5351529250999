import axios from 'axios';
import { action, observable, computed } from 'mobx';
import { once } from 'ramda'
class Basis {
  @observable private _recordList = [] as Array<{ _id: string, recordCode: string, title: string }>;
  @observable private _userList = [] as Array<{ _id: string, qywxUserId: string, name: string, available: boolean }>;
  @observable private _informationList = [] as Array<{ _id: string, name: string }>;
  @observable private _conferenceList = [] as Array<{ _id: string, title: string, code: string }>;
  @observable private _companyList = [] as { _id: string, industry: string, permissions: string[], abbreviation: string, companyName?: string }[];
  @observable private _projectList = [] as Array<{ _id: string, projectName: string, projectCode: string }>;
  @observable private _industryList = [] as Array<{ _id: string, name: string }>;
  @observable private _positionList = [] as Array<{ _id: string, positionName: string }>;
  @observable private _greaterDepartmentNames = [] as Array<{ _id: string, name: string, parentDepartment?: string }>;


  constructor() {
    this.getUserList();
    this.getPositionList();
    setTimeout(() => {
      this.fetchOnceConferenceList()
      this.fetchOnceInformationList()
      this.fetchOnceUserList()
      this.fetchOnceGreaterDepartmentNames()
    }, 1000)
  }

  public fetchGreaterDepartmentNames = () => {
    return axios.get('/api/greaterDepartment/names').then(res => res.data.data.list).then(list => this._greaterDepartmentNames = list)
  }
  private fetchOnceGreaterDepartmentNames = once(() => {
    this.fetchGreaterDepartmentNames()
  })

  /** 业务中心名字 */
  @computed
  public get greaterDepartmentNames() {
    this.fetchOnceGreaterDepartmentNames()
    return this._greaterDepartmentNames
  }

  private fetchOnceInformationList = once(() => {
    this.getInformationList();
  })

  @computed
  get informationList() {
    this.fetchOnceInformationList();
    return this._informationList;
  }


  @action.bound
  getInformationList() {
    axios.get(`/api/information/list/status`) // 跟据跟进次数排序
      .then(res => res.data)
      .then(res => {
        this._informationList = res.data.list;
      })
  }

  private fetchOncePositionList = once(() => {
    this.getPositionList();
  })


  @computed
  get positionList() {
    this.fetchOncePositionList();
    return this._positionList;
  }

  @action.bound
  getPositionList() {
    axios.get("/api/position/list")
      .then(res => res.data)
      .then(res => {
        this._positionList = res.data.list;
      })
  }

  private fetchOnceIndustryList = once(() => {
    this.getIndustryList();
  })

  @computed
  get industryList() {
    this.fetchOnceIndustryList();
    return this._industryList;
  }

  @action.bound
  getIndustryList() {
    axios.post(`/api/industry/list`, {})
      .then(res => res.data)
      .then(res => {
        this._industryList = res.data.list;
      })
  }

  private fetchOnceCompanyList = once(() => {
    this.getCompanyList();
  })

  @computed
  get companyList() {
    this.fetchOnceCompanyList();
    return this._companyList;
  }

  @action.bound
  getCompanyList() {
    return axios.get(`/api/company/list`)
      .then(res => res.data)
      .then(res => {
        this._companyList = res.data.list;
      })
  }

  private fetchOnceProjectList = once(() => {
    this.getProjectList();
  })

  @computed
  get projectList() {
    this.fetchOnceProjectList();
    return this._projectList;
  }

  @action.bound
  getProjectList() {
    axios.get(`/api/project/list`)
      .then(res => res.data)
      .then(res => {
        this._projectList = res.data.list;
      })
  }

  private fetchOnceConferenceList = once(() => {
    this.getConferenceList();
  })


  @computed
  get conferenceList() {
    this.fetchOnceConferenceList();
    return this._conferenceList;
  }

  @action.bound
  public getConferenceList() {
    axios.get("/api/conference/list")
      .then(res => res.data)
      .then(res => {
        this._conferenceList = res.data.list || [];
      })
  }

  private fetchOnceUserList = once(() => {
    this.getUserList();
  })

  @computed
  get userList() {
    this.fetchOnceUserList();
    return this._userList;
  }

  @action.bound
  getUserList() {
    axios.get("/api/user/list")
      .then(res => res.data)
      .then(res => {
        this._userList = res.data.list || [];
      })
  }

  private fetchOnceRecordList = once(() => {
    this.getReacordList();
  })

  @computed
  get recordList() {
    this.fetchOnceRecordList();
    return this._recordList;
  }

  @action.bound
  getReacordList() {
    axios.get("/api/record/list")
      .then(res => res.data)
      .then(res => {
        this._recordList = res.data.list || [];
      })
  }
}

export const basis = new Basis();
