import { message } from 'antd';
import axios from 'axios';
// axios.defaults.baseURL = typeof window === "object" ? "/" : `http://localhost:${process.env.PORT || "3000"}/`;
axios.defaults.timeout = 18000;
axios.defaults.withCredentials = true;

axios.interceptors.response.use((response) => {
    if (typeof response.data === 'object' && typeof response.data?.message === 'string' && response.data?.message) {
        if (response.data.code) message.error(response.data.message)
        else message.success(response.data.message)
    }
    return response;
}, (error) => {
    const errMsg = (error.response && error.response.data && error.response.data.message) || error.toString();
    const code = (error.response && error.response.data && error.response.data.code) || 'UNDEFINED';
    // tslint:disable-next-line:no-console
    console.error(`--axios请求报错，请求地址：${error.config.url}\n`, `--请求body：`, (error.config && error.config.data) || error.response);
    message.error(errMsg);

    if ([60007, 60008, 60009, 60010].includes(code)) {
        if (window.location.href.includes('levect.com')) {
            location.href = `https://sso.levect.com/#/login?redirect_uri=https%3A%2F%2F${window.location.host}%2FloginByToken`
        } else {
            location.href = 'https://sso.levect.com/#/login?redirect_uri=http%3A%2F%2Flocalhost%3A9031%2FloginByToken'
        }
    }
    return Promise.reject(error);
});
