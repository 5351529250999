import { ConfigProvider, Result, Button, Spin } from 'antd';
import zhCN from 'antd/lib/locale-provider/zh_CN';
import { toJS } from 'mobx';
import { BrowserRouter, Route, Switch } from 'react-router-dom';
import Layout from './Layout';
import { observer } from 'mobx-react';
import React, { lazy, Suspense } from 'react';
import { Global, css } from '@emotion/core';

window.toJS = toJS;
window.React = React;

const App: React.FC = observer(() => {
    return (
        <ErrorBoundary>
            <Global styles={css`
                html, body{
                    background: #f0f2f5;
                    .flex{
                        display: flex;
                    }
                    .ant-layout-header {
                        background: #ffffff;
                    }
                    @keyframes feadIn {
                        from{
                            opacity: 0;
                        }
                        to{
                            opacity: 1
                        }
                    }
                    .mainContent{
                        height: 100%;
                        overflow-y: auto;
                    }
                    .mainContent>div{
                        animation: feadIn 500ms both;
                    }
                }
            `}></Global>
            <Suspense fallback={<Loading />}>
                <ConfigProvider locale={zhCN}>
                    <BrowserRouter>
                        <Switch>
                            <Route exact path='/test' component={lazy(() => import('./test/index'))} />
                            <Route exact path='/login' component={lazy(() => import('./login'))} />
                            <Route exact path='/loginByToken' component={lazy(() => import('./loginByToken'))} />
                            <Route path="*">
                                <Layout>
                                    <Suspense fallback={<Loading />}>
                                        <div className="mainContent">
                                            <Switch>
                                                <Route exact path="/staff" component={lazy(() => import('./staff'))}></Route>
                                                <Route exact path="/staff/record" component={lazy(() => import('./staff/record'))}></Route>
                                                <Route exact path="/staff/add" component={lazy(() => import('./staff/add'))}></Route>
                                                <Route exact path="/staff/:staffId" component={lazy(() => import('./staff/add'))}></Route>
                                                <Route exact path="/flow/add" component={lazy(() => import('./flow/add'))}></Route>
                                                <Route exact path="/flow/show/:flowId" component={lazy(() => import('./flow/add'))}></Route>
                                                <Route exact path="/flow/:flowId" component={lazy(() => import('./flow/add'))}></Route>
                                                <Route exact path="/flow" component={lazy(() => import('./flow'))}></Route>
                                                <Route exact path="/basis/industry" component={lazy(() => import('./basis/industry'))}></Route>
                                                <Route exact path="/basis/company" component={lazy(() => import('./basis/company'))}></Route>
                                                <Route exact path="/basis/greaterDepartment" component={lazy(() => import('./basis/greaterDepartment'))}></Route>
                                                <Route exact path="/basis/staffGrow" component={lazy(() => import('./basis/project'))}></Route>
                                                <Route exact path="/basis/project" component={lazy(() => import('./basis/project'))}></Route>
                                                <Route exact path="/basis/project/show" component={lazy(() => import('./basis/project/show'))}></Route>
                                                <Route exact path="/basis/position" component={lazy(() => import('./basis/position'))}></Route>
                                                <Route exact path="/customer" component={lazy(() => import('./customer'))}></Route>
                                                <Route exact path="/customer/information" component={lazy(() => import('./customer/information'))}></Route>
                                                <Route exact path="/customer/contract" component={lazy(() => import('./customer/contract'))} ></Route>
                                                <Route exact path={["/customer/details/search", "/customer/details/basis/project", "/customer/details/basis/staffGrow"]} component={lazy(() => import('./customer/details'))}></Route>
                                                <Route exact path="/customer/record" component={lazy(() => import('./customer/record'))}></Route>
                                                <Route exact path="/customer/record/add" component={lazy(() => import('./customer/record/add'))} ></Route>
                                                <Route exact path="/customer/record/:id" component={lazy(() => import('./customer/record/add'))} ></Route>
                                                <Route exact path="/customer/conference" component={lazy(() => import('./customer/conference'))}></Route>
                                                <Route exact path="/search" component={lazy(() => import('./search'))}></Route>
                                                <Route exact path={["/search/show", "/search/show?:id"]} component={lazy(() => import('./search/show'))} ></Route>

                                                <Route exact path={["/project/record/list", "/project/record/list?:id"]} component={lazy(() => import('./basis/project/list'))}></Route>
                                                <Route exact path={["/company/record/list", "/company/record/list?:id"]} component={lazy(() => import('./basis/company/list'))}></Route>
                                                <Route exact path="/statistics" component={lazy(() => import('./statistics/index'))}></Route>
                                                <Route exact path="/" component={lazy(() => import('./index/index'))}></Route>
                                                <Route path='*' component={() => <Result
                                                    status="404"
                                                    title="404"
                                                    subTitle="Sorry, the page you visited does not exist."
                                                    extra={
                                                        <Button type="primary" onClick={() => location.href = '/'}>
                                                            返回首页
                                                </Button>
                                                    }
                                                />} />
                                            </Switch>
                                        </div>
                                    </Suspense>
                                </Layout>
                            </Route>
                        </Switch>
                    </BrowserRouter>
                </ConfigProvider>
            </Suspense>
        </ErrorBoundary>
    );
});

export default App;

class ErrorBoundary extends React.Component {
    public state = {
        hasError: false,
        error: '',
    };

    public componentDidCatch(error: any, info: any) {
        this.setState({
            hasError: true,
            error,
        });
        console.log('ErrorBoundary!!!');
    }

    public render() {
        if (this.state.hasError) {
            return <div>
                <h1>发生临时错误：{this.state.error.toString()}</h1>
                <div style={{ cursor: 'pointer', color: '#44aa88' }} onClick={() => location.reload()}>点击刷新尝试修复</div>
            </div>;
        }
        return this.props.children;
    }
}



const Loading = () => (
    <div style={{ height: '100vh', display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
        <Spin />
    </div>
)