import { Layout, Menu, Space, Avatar, Dropdown, Spin, Result, Modal } from 'antd';
import {
    MenuUnfoldOutlined, MenuFoldOutlined,
    UsergroupAddOutlined,
    SettingFilled, SolutionOutlined,
    LogoutOutlined, UnlockOutlined,
    ReconciliationOutlined, FileProtectOutlined,
    IdcardOutlined, HomeOutlined,
    SearchOutlined, LaptopOutlined, ExclamationCircleOutlined, PartitionOutlined
} from '@ant-design/icons';
import { toJS } from 'mobx';
import React, { useEffect, useState, useCallback, createElement } from 'react';
import { Global, css } from '@emotion/core';
import { useLocation, useHistory } from 'react-router-dom'
import styled from '@emotion/styled';
import Axios from 'axios';
import { observer } from 'mobx-react';
import rootStore from './store';
import { useObservable } from 'rxjs-hooks';
import { of, timer } from 'rxjs';
import { filter, tap, mapTo, switchMapTo, startWith, skip } from 'rxjs/operators';

const { SubMenu } = Menu;

window.toJS = toJS;
window.React = React;
const { Header, Sider, Content } = Layout;


const Index: React.FC = (props) => {
    const location = useLocation()
    const history = useHistory()
    const [collapsed, setCollapsed] = useState(false)
    const toggle = () => {
        setCollapsed(v => !v)
    };
    const logout = useCallback(() => {
        Modal.confirm({
            title: '退出确认',
            icon: <ExclamationCircleOutlined translate={{}} />,
            content: '确认退出登录吗？',
            onOk() {
                Axios.get('/api/logout').then(res => {

                    window.location.href = window.location.hostname.includes('levect.com') ?
                        `https://sso.levect.com/#/login?redirect_uri=https%3A%2F%2F${window.location.host}%2FloginByToken` :
                        `https://sso.levect.com/#/login?redirect_uri=${encodeURIComponent(`http://${window.location.host}/loginByToken`)}`
                })
            }
        });
    }, [])

    const modifyPassword = useCallback(() => {
        Modal.confirm({
            title: '离开确认',
            icon: <ExclamationCircleOutlined translate={{}} />,
            content: '确认要跳转到sso来修改密码吗？',
            onOk() {
                window.location.href = 'https://sso.levect.com/#/me'
            }
        });
    }, [])

    useEffect(() => {
        if (!rootStore.user?.phone) {
            Axios.get('/api/user/me').then(res => res.data).then(data => {
                rootStore.user = data.data;
                (window as any).rootStore = window.toJS(rootStore)
            })
        }
    }, [])

    useEffect(() => {
        if (rootStore.user) {
            Axios.get('/api/login/isLogin').then(res => {
                if (res.data.data.state === 0) {
                    window.location.href = window.location.hostname.includes('levect.com') ?
                        `https://sso.levect.com/#/login?redirect_uri=https%3A%2F%2F${window.location.host}%2FloginByToken` :
                        `https://sso.levect.com/#/login?redirect_uri=http%3A%2F%2F${window.location.hostname}:${window.location.port}%2FloginByToken`
                }
            })
        }
    }, [rootStore.user])
    const collapsedIcon = createElement(collapsed ? MenuUnfoldOutlined : MenuFoldOutlined, {
        className: 'trigger',
        onClick: toggle,
        translate: {},
        style: { fontSize: 20 }
    })

    /** 自动收起菜单 */
    useObservable<boolean, [string]>((_, input$) => input$.pipe(
        skip(1),
        filter(() => window.innerWidth < 700),
        switchMapTo(timer(1000)),
        mapTo(true),
        tap(v => setCollapsed(v)),
    ), true, [location.pathname])


    if (!rootStore.user) return <div style={{ width: '100vw', height: '100vh', display: 'flex', alignItems: 'center', justifyContent: 'center' }}><Spin /></div>
    if (!rootStore.user?.platform.includes(6)) return <Result status="403" title="您没有权限登录 simba" />
    return (
        <Layout style={{ height: '100vh' }}>
            <Global styles={css`
                html, body{
                    background: #f0f2f5;
                    .flex{
                        display: flex;
                    }
                    .ant-layout-header {
                        background: #ffffff;
                    }
                }
                .logo:hover{
                    cursor: pointer;
                }
                .ant-layout-sider-zero-width-trigger.ant-layout-sider-zero-width-trigger-left{
                    top: 10px;
                    transform: translateX(10px);
                    background: #ffffff;
                    color: #555;
                }
                @media(max-width: 669px){
                    .sider{
                        position: absolute;
                        z-index:1;
                    }
                }
            `}></Global>
            <Sider className="sider" {...(document.documentElement.clientWidth > 992 ? {} : { trigger: collapsedIcon, collapsedWidth: 0 })} onCollapse={v => setCollapsed(v)} breakpoint="lg" style={{ boxShadow: '2px 0 6px rgba(0,21,41,.35)', height: '100vh', overflowY: 'auto' }} collapsible collapsed={collapsed} >
                <div onClick={() => history.push('/')} style={{ padding: 24, display: 'flex' }} className="logo">
                    {!collapsed && <h1 style={{ fontSize: 20, color: 'white', width: '100%', textAlign: 'center', margin: 0 }}>SIMBA</h1>}
                </div>
                <Menu theme="dark" mode="inline" selectedKeys={[location.pathname]}>
                    <Menu.Item onClick={() => history.push('/')} key="/">
                        <HomeOutlined translate={{}} />
                        <span>首页</span>
                    </Menu.Item>
                    <Menu.Item onClick={() => history.push('/search')} key="/search">
                        <SearchOutlined translate={{}} />
                        <span>跟进查询</span>
                    </Menu.Item>
                    <Menu.Item onClick={() => history.push('/customer/record')} key="/customer/record">
                        <ReconciliationOutlined translate={{}} />
                        <span>我的跟进</span>
                    </Menu.Item>
                    <Menu.Item onClick={() => history.push('/customer')} key="/customer">
                        <UsergroupAddOutlined translate={{}} />
                        <span>客户管理</span>
                    </Menu.Item>
                    <Menu.Item onClick={() => history.push('/flow')} key="/flow">
                        <PartitionOutlined style={{ marginRight: 10 }} translate={{}} />
                        <span>流程管理</span>
                    </Menu.Item>
                    <Menu.Item onClick={() => history.push('/staff')} key="/staff">
                        <LaptopOutlined translate={{}} />
                        <span>员工发展</span>
                    </Menu.Item>
                    <Menu.Item onClick={() => history.push('/basis/project')} key="/basis/project">
                        <LaptopOutlined translate={{}} />
                        <span>项目管理</span>
                    </Menu.Item>
                    <Menu.Item onClick={() => history.push('/customer/conference')} key="/customer/conference">
                        <ReconciliationOutlined translate={{}} />
                        <span>会议管理</span>
                    </Menu.Item>
                    <Menu.Item onClick={() => history.push('/basis/company')} key="/basis/company">
                        <PartitionOutlined translate={{}} />
                        <span>公司管理</span>
                    </Menu.Item>
                    <Menu.Item onClick={() => history.push('/customer/contract')} key="/customer/contract">
                        <FileProtectOutlined translate={{}} />
                        <span>合同信息</span>
                    </Menu.Item>
                    <Menu.Item onClick={() => history.push('/statistics')} key="/statistics">
                        <SearchOutlined translate={{}} />
                        <span>统计报表</span>
                    </Menu.Item>
                    <SubMenu key="basis" icon={<SettingFilled translate={{}} />} title="基础模块">
                        <Menu.Item onClick={() => history.push('/basis/industry')} key="/basis/industry">
                            <SolutionOutlined translate={{}} />
                            <span>行业配置</span>
                        </Menu.Item>
                        <Menu.Item onClick={() => history.push('/basis/position')} key="/basis/position">
                            <IdcardOutlined translate={{}} />
                            <span>职位配置</span>
                        </Menu.Item>
                        <Menu.Item onClick={() => history.push('/basis/greaterDepartment')} key="/basis/greaterDepartment">
                            <IdcardOutlined translate={{}} />
                            <span>业务中心配置</span>
                        </Menu.Item>
                    </SubMenu>
                </Menu>
                <div style={{ height: 70 }}></div>
            </Sider>

            <Layout className="site-layout">
                <Header className="site-layout-background" style={{ boxShadow: '0 1px 4px rgba(0,21,41,.08)', padding: '0 25px', display: 'flex', alignItems: 'center', justifyContent: 'space-between' }}>
                    {collapsedIcon}
                    <MyDropdown overlay={
                        <Menu style={{ width: 150 }} selectedKeys={[]} onClick={console.log}>
                            {/* <Menu.Divider /> */}
                            <Menu.Item key="xiugai">
                                <span onClick={modifyPassword}>
                                    <UnlockOutlined style={{ marginRight: 8 }} translate={{}} /> 修改密码
                                </span>
                            </Menu.Item>
                            <Menu.Item onClick={logout} key="logout">
                                <LogoutOutlined translate={{}} /> 退出登录
                            </Menu.Item>
                        </Menu>
                    }>
                        <Space>
                            <Avatar style={{ marginTop: -3 }} size="small" src={rootStore.user.userUrl || "https://gw.alipayobjects.com/zos/antfincdn/XAosXuNZyF/BiazfanxmamNRoxxVxka.png"} alt="avatar" />
                            <span>{rootStore.user?.userName}</span>
                        </Space>
                    </MyDropdown>
                </Header>
                <MyContent
                    className="site-layout-background">
                    {props.children}
                </MyContent>
            </Layout>
        </Layout >
    );
};

export default observer(Index)

const MyContent = styled(Content)`
    margin: ${window.innerWidth > 700 ? 15 : 0}px 0;
    @media(min-width: 700px){
        margin: 15px;
    }
`;

const MyDropdown = styled(Dropdown)`
    cursor: pointer;
    padding: 0 15px;
    &:hover{
        background: #f1f1f1;
    }
`;