import { observable } from 'mobx';
import { basis } from './basis';

const rootStore = observable({
    get isAdmin() {
        return !!this.user?.roles.some((item: any) => item.code === 'crm' || item.code === 'simba')
    },
    user: undefined as crm.ISessionUser | undefined,
    basis: basis,
})

export default rootStore;
